<template>
  <main
    class="relative h-screen w-screen overflow-hidden overflow-y-scroll font-light"
    :class="{ 'cursor-progress': isCheckoutLoading }">
    <!-- Estimate -->
    <div class="container flex gap-4 pt-16 max-lg:flex-col md:pt-24 lg:gap-16 lg:pt-0">
      <!-- Left: Map -->
      <div class="relative mx-auto w-full md:w-3/4 lg:w-7/12">
        <div class="top-8 flex h-full max-h-[calc(100vh-120px)] min-h-[200px] flex-col justify-center lg:sticky lg:h-screen lg:pt-[10vh]">
          <div
            id="left"
            ref="left"
            class="aspect-square size-full max-h-[800px]">
            <!-- Image with Testimonials -->
            <LazyTestimonials
              :class="!booking.service_id ? 'z-10 opacity-100' : 'absolute -z-10 opacity-0'"
              :show-background="true" />

            <!-- Map -->
            <LazyMap
              :waypoints="locationCoordinates"
              :waypoints-route="route"
              :boundary="boundary"
              class="h-full max-md:hidden"
              :class="booking.service_id ? 'pointer-events-auto z-10 opacity-100' : 'pointer-events-none absolute -z-10 opacity-0'">
              <div
                v-if="estimate"
                class="absolute bottom-10 left-1/2 z-50 flex w-full -translate-x-1/2 flex-wrap justify-center gap-2 px-4">
                <TimeBadge
                  :time="estimate?.labourTime"
                  label="Labour" />
                <TimeBadge
                  :time="estimate?.travelTime"
                  label="Travel" />
              </div>
            </LazyMap>
          </div>
        </div>
      </div>

      <!-- Right: Form -->
      <div
        class="relative z-10 mx-auto flex w-full flex-col gap-16 max-lg:!mt-4 max-lg:!pt-4 md:w-3/4 md:pb-[75vh] lg:w-5/12 lg:gap-32 lg:pb-[50vh]"
        :style="{ marginTop: `${paddingTop}px` }">
        <!-- Regions -->
        <OrderRegions
          v-if="regionConfig?.length > 1"
          v-model="booking.region_id"
          :active="activeSection === 'regions'" />

        <!-- Services -->
        <OrderServices
          id="services"
          v-model="booking.service_id"
          :region="booking.region_id"
          :active="activeSection === 'services'"
          :class="booking.region_id ? 'active' : 'inactive'"
          :style="{ scrollMarginTop: `${paddingTop}px` }" />

        <!-- Timeslot -->
        <div
          id="timeslots"
          :style="{ scrollMarginTop: `${paddingTop}px` }">
          <LazyOrderTimeslot
            v-model:date="booking.date"
            v-model:timeslot-id="booking.timeslot_id"
            :service="booking.service_id"
            :region="booking.region_id"
            :active="activeSection === 'timeslots'"
            :class="serviceComplete ? 'active' : 'inactive'" />
        </div>

        <!-- Locations -->
        <LazyOrderLocations
          v-model="booking.locations"
          :region="booking.region_id"
          :service="booking.service_id"
          :boundary="boundary"
          :active="activeSection === 'locations'"
          :class="timeslotComplete ? 'active' : 'inactive'"
          :style="{ scrollMarginTop: `${paddingTop}px` }"
          @complete="locationsComplete = $event" />

        <!-- Items -->
        <LazyOrderItems
          id="items"
          v-model:description="booking.description"
          v-model:attachments="booking.attachments"
          v-model:volume="booking.volume"
          v-model:weight="booking.weight_id"
          v-model:heavy="booking.heavy"
          v-model:item-types="booking.item_types"
          v-model:assisting-hauler="booking.assisting"
          :service-type="service"
          :region="booking.region_id"
          :active="activeSection === 'items'"
          :class="locationsComplete ? 'active' : 'inactive'"
          :style="{ scrollMarginTop: `${paddingTop}px` }"
          @complete="itemsComplete = $event"
          @started="itemsStarted = $event" />

        <template v-if="!customQuote">
          <!-- Excluded Items -->
          <OrderExcludedItems
            id="excludeditems"
            v-model="booking.safe"
            :service="booking.service_id"
            :active="activeSection === 'excludeditems'"
            :class="itemsComplete || excludedItemsComplete ? 'active' : 'inactive'"
            :style="{ scrollMarginTop: `${paddingTop}px` }" />

          <!-- Estimate -->
          <section
            v-if="booking.safe && estimate"
            id="total"
            class="flex flex-col gap-12"
            :style="{ scrollMarginTop: `${paddingTop}px` }">
            <OrderPrice
              :service="booking.service_id"
              :service-type="service"
              :date="booking.date"
              :timeslot-id="booking.timeslot_id"
              :labour-cost="estimate.labourCost"
              :floor-cost="estimate.floorCost"
              :travel-cost="estimate.travelCost"
              :toll-cost="estimate.tollCost"
              :waste-cost="estimate.wasteCost"
              :total-cost="estimate.totalCost"
              :markup="estimate.markup"
              :distance="estimate.travelDistance"
              :people-required="estimate.peopleRequired"
              :customer-assisting="booking.customerAssisting"
              :show-savings="true"
              :timeslot-display="estimate.timeslotDisplay"
              :class="excludedItemsComplete ? 'active' : 'inactive'"
              :style="{ scrollMarginTop: `${paddingTop}px` }"
              @apply-assiting-saving="applyAssitingSaving" />

            <!-- Checkout -->
            <OrderUserCheckout
              :is-submitting="isCheckoutLoading"
              :total-cost="estimate.totalCost"
              @checkout="goToCheckout" />
          </section>
        </template>
      </div>
    </div>

    <!-- Footer -->
    <BlockFooter />
    <!-- Afterpay Modal -->
    <ModalAfterpay v-if="showAfterpayModal" />
    <!-- Zip pay Modal -->
    <ModalZip v-if="showZipModal" />
  </main>
</template>

<script setup lang="ts">
// Imports
import { v4 as uuidv4 } from 'uuid'

const props = defineProps({
  serviceOffer: {
    type: String,
    default: null,
  },
})

useHead({
  title: 'PicUp | Booking Form',
})

// Constants
const supabase = useSupabaseClient<Database>()
const { gtag } = useGtag()
const user = useSupabaseUser()
const { $sentryAddBreadcrumb, $sentryCaptureException } = useNuxtApp()
const showAfterpayModal = useState('showAfterpayModal', () => false)
const showZipModal = useState('showZipModal', () => false)

const { data: regionData } = await useAsyncData(
  'regionData',
  async () => {
    let query = supabase
      .from('regions')

    // check for associated regions if user is logged in
    if (user.value) {
      query = query
        .select('id, name, boundary, timezone, is_company_region, allowed_regions:users_regions_1(*), services:region_services(services_id(id, key, name, description, status), timeslots(id, start, end, capacity), sort)')
        .eq('users_regions_1.users_id', user.value.id)
        .eq('status', 'published')
    }
    else {
      query = query
        .select('id, name, boundary, timezone, is_company_region, services:region_services(services_id(id, key, name, description, status), timeslots(id, start, end, capacity), sort)')
        .eq('status', 'published')
        .eq('is_company_region', 'false')
    }

    const { data, error } = await query

    if (error) throw error

    // filter regions with only what's associated with the logged in user
    let filteredResult = data
    if (user.value) {
      filteredResult = data.filter(region => region.allowed_regions.length !== 0)
    }
    // if no regions are left, have default regions display
    if (filteredResult.length == 0) {
      filteredResult = data.filter(region => region.is_company_region === false)
    }

    // Filter to get only the offered service when accessed from /bookings-removals URL
    if (props.serviceOffer) {
      return filteredResult.map(region => ({
        ...region,
        services: region.services
          .filter(service => service.services_id.key === props.serviceOffer)
          .map(service => ({
            id: service.services_id.id,
            key: service.services_id.key,
            name: service.services_id.name,
            description: service.services_id.description,
            status: service.services_id.status,
            timeslots: service.timeslots,
            sort: service.sort,
          })),
      }))
    }

    return filteredResult.map(region => ({
      ...region,
      services: region.services.map(service => ({
        id: service.services_id.id,
        key: service.services_id.key,
        name: service.services_id.name,
        description: service.services_id.description,
        status: service.services_id.status,
        timeslots: service.timeslots,
        sort: service.sort,
      })),
    }))
  },
)

// For unavailable dates
const { data: unavailableDates } = await useLazyAsyncData(
  'unavailable_dates',
  async () => {
    const { data, error } = await supabase
      .from('unavailable_dates_regions')
      .select('unavailable_date:unavailable_dates_id(date, label), regions_id')

    if (error) throw error
    return data.reduce((acc, row) => {
      if (!acc[row.regions_id]) acc[row.regions_id] = []
      acc[row.regions_id].push(row.unavailable_date)
      return acc
    }, {})
  },
)

// Cookies
// const bookingFormCookie = useCookie('picup_booking_form', { decode: JSON.parse, readonly: true })

// State
const regionConfig = useState('regionConfig', () => regionData || [])
useState('unavailableDatesConfig', () => unavailableDates || [])

// Loading
const isCheckoutLoading = ref(false)

const customQuote = computed(() => {
  return (booking.value.volume as string) === 'quote' || booking.value.weight_id === 'quote'
})

// Map Position
const left = ref(null)
const activeSection = ref('regions')
const { top } = useElementBounding(left)
const paddingTop = computed(() => Math.max(top.value, 80))

// Services
const regionsComplete = computed(() => !!booking.value.region_id)
const serviceComplete = computed(() => !!booking.value.service_id)
const locationsComplete = ref(false)
const itemsStarted = ref(false)
const itemsComplete = ref(false)
const excludedItemsComplete = computed(() => !!booking.value.safe)
const timeslotComplete = computed(() => !!booking.value.date && !!booking.value.timeslot_id)

const boundary = computed(() => {
  const region = regionConfig.value?.find((region: { id: string }) => region.id === booking.value?.region_id)
  return region?.boundary || null
})

const service = computed(() => {
  return regionConfig.value
    ?.find((region: { id: string }) => region.id === booking.value?.region_id)
    ?.services.find((service: { id: string }) => service.id === booking.value?.service_id)?.key
})

// Booking Form
const booking = ref({
  region_id: '',
  service_id: '',
  date: '',
  timeslot_id: '',
  volume: '',
  weight_id: 1,
  description: '',
  attachments: [],
  item_types: [],
  heavy: false,
  assisting: false,
  safe: false,
  locations: [
    {
      id: uuidv4(),
      type: 'pickup',
      name: '',
      address: '',
      coordinates: [],
      contact_name: '',
      contact_phone: '',
      instructions: '',
      floors: 0,
    },
    {
      id: uuidv4(),
      type: 'delivery',
      name: '',
      address: '',
      coordinates: [],
      contact_name: '',
      contact_phone: '',
      instructions: '',
      floors: 0,
    },
  ],
})

// Locations
debouncedWatch(
  () => [booking.value, locationsComplete.value, itemsComplete.value],
  async ([newBooking, newLocations, newItems]) => {
    const locationsHaveCoordinates = newBooking.locations.every(location => location?.coordinates?.length == 2)
    if (newBooking.service_id && newBooking.locations.length >= 2 && locationsHaveCoordinates) refreshEstimate()

    await nextTick()

    // TODO: Uncomment this when we need to support multiple regions again
    if (!regionsComplete.value) {
      scrollToAnchorPatched('regions'), (activeSection.value = 'regions')
    }
    else if (!serviceComplete.value) {
      scrollToAnchorPatched('services'), (activeSection.value = 'services')
    }
    else if (!timeslotComplete.value) {
      scrollToAnchorPatched('timeslots'), (activeSection.value = 'timeslots')
    }
    else if (!locationsComplete.value) {
      scrollToAnchorPatched('locations'), (activeSection.value = 'locations')
    }
    else if (itemsStarted.value && !itemsComplete.value) return
    else if (excludedItemsComplete.value && estimate.value) {
      scrollToAnchorPatched('total'), (activeSection.value = 'total')
    }
    else if (itemsStarted.value && itemsComplete.value) {
      scrollToAnchorPatched('excludeditems'), (activeSection.value = 'excludeditems')
    }
    else if (!itemsComplete.value) {
      scrollToAnchorPatched('items'), (activeSection.value = 'items')
    }
    else if (!excludedItemsComplete.value) {
      scrollToAnchorPatched('excludeditems'), (activeSection.value = 'excludeditems')
    }
    else {
      scrollToAnchorPatched('total'), (activeSection.value = 'total')
    }
  },
  { debounce: 100, deep: true, immediate: false },
)

watch(
  () => service.value,
  (newValue) => {
    booking.value.timeslot_id = ''
    booking.value.weight_id = 1
    booking.value.locations[1] = {
      id: uuidv4(),
      type: newValue === 'waste' ? 'waste' : 'delivery',
      address: booking.value.locations[1].address || '',
      coordinates: booking.value.locations[1].coordinates || [],
      name: booking.value.locations[1].name || '',
      contact_name: '',
      contact_phone: '',
      instructions: '',
      floors: 0,
    }
  },
)

// Estimate
const { data: estimate, refresh: refreshEstimate } = await useFetch('/api/estimate', {
  method: 'POST',
  body: booking,
  lazy: true,
  immediate: false,
  server: false,
  watch: false,
})

const locationCoordinates = computed(() => booking.value?.locations.map(location => location.coordinates))

const { data: route, refresh: refreshDirections } = await useFetch('/api/directions', {
  method: 'POST',
  body: { coordinates: locationCoordinates },
  immediate: false,
  server: false,
  watch: [locationCoordinates],
  transform: (data: any) => {
    return {
      ...data.routes[0],
    }
  },
})

async function goToCheckout() {
  // Post the form to the checkout API
  // If user is paylater, they will be redirected to the booking page
  // Otherwise, they will be redirected to the Stripe checkout page
  isCheckoutLoading.value = true

  $sentryAddBreadcrumb({
    category: 'checkout',
    message: 'User is checking out',
  })

  // Add a pause of 2 seconds to allow the user state to update
  await new Promise(resolve => setTimeout(resolve, 2000))

  const { data, error } = await useFetch('/api/checkout', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: {
      ...booking.value,
      affiliate_id: affiliateId.value ? decodeAffiliateIdToUserId(affiliateId.value) : null,
    },
  })

  if (error.value) {
    isCheckoutLoading.value = false
    console.warn('Issue creating order', { ...error.value })

    $sentryAddBreadcrumb({
      category: 'checkout',
      message: 'Error creating order',
    })

    $sentryCaptureException(error.value, {
      extra: {
        booking: unref(booking.value),
        error: error.value.data,
      },
      user: {
        id: user.value?.id,
        email: user.value?.email,
      },
    })

    alert(`There was an issue with checkout. Please refresh your page completely and try again.`)
    return
  }

  try {
    gtag('event', 'checkout', {
      currency: 'AUD',
      value: estimate.value ? (Number(estimate.value.totalCost) / 100).toFixed(2) : 0,
      items: [
        {
          item_id: service.value,
          price: estimate.value ? (Number(estimate.value.totalCost) / 100).toFixed(2) : 0,
        },
      ],
    })
  }
  catch (error) {
    console.error('Issue tracking checkout', error)
  }

  isCheckoutLoading.value = false
  navigateTo((data.value as { url: string }).url, { redirectCode: 303, external: true })
}

const applyAssitingSaving = (haulerSavings: number) => {
  booking.value.assisting = true
}

// Affiliate
const affiliateId = useCookie('picup_affiliate')
const affiliateIdQuery = useRoute().query.a
if (affiliateIdQuery) affiliateId.value = affiliateIdQuery as string

onMounted(() => {
  booking.value.safe = false

  // if (bookingFormCookie.value) {
  // booking.value.service = bookingFormCookie.value.service // FIXME: Pass the service or the service ID and region
  // booking.value.locations = [...bookingFormCookie.value.locations]
  // }

  // Automatically select the first available region if options are hidden
  if (regionData.value && regionData.value.length == 1) {
    booking.value.region_id = regionData.value[0].id
  }
})
</script>

<style scoped>
  .active {
    @apply opacity-100 transition-all;
  }

  .inactive {
    @apply pointer-events-none opacity-10 transition-all;
  }
</style>
